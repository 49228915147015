import { Component } from '@angular/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NavController} from '@ionic/angular';
import { Network } from '@ionic-native/network/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  isNetworkConnected: boolean;
  // season: any;
  farm: any;
  admin: any;
  // crop: any;
  // harvest: any;
  theme: boolean = true;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private network: Network,
    public navController: NavController
  ) {
    this.initializeApp();
    this.sideMenu();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.checkNetworkConnection()

      this.statusBar.styleDefault();
      this.splashScreen.hide();
      document.body.classList.add('dark');
    });
    this.initTranslate();
    // this.getNetworkStatus()
  }

  checkNetworkConnection() {
    this.network.onDisconnect().subscribe(() => {
      console.log('Network was disconnected :-(');
      // You can trigger any notification method here
    });
  
    this.network.onConnect().subscribe(() => {
      console.log('Network was connected :-)');
      // You can trigger any action on network reconnection
    });
    window.addEventListener('online', () => {
      // this.isNetworkConnected = true;
      console.log('Network was connected :-)')
    });
    window.addEventListener('offline', () => {
      console.log('Network was disconnected :-(');    
    });
  }


  initTranslate() {
    const enLang = 'en';

    // Set the default language for translation strings, and the current language.
    this.translate.setDefaultLang(enLang);

    if (this.translate.getBrowserLang() !== undefined) {
      this.translate.use(this.translate.getBrowserLang());
    } else {
      this.translate.use(enLang); // Set your language here
    }

    // this.translate.get(['BACK_BUTTON_TEXT']).subscribe(values => {
    //   this.config.set('ios', 'backButtonText', values.BACK_BUTTON_TEXT);
    // });
  }

  private initializeNetworkMonitoring() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.network.onDisconnect().subscribe(() => {
          this.isNetworkConnected = false;
        });

        this.network.onConnect().subscribe(() => {
          this.isNetworkConnected = true;
        });
      } else {
        window.addEventListener('online', () => {
          this.isNetworkConnected = true;
        });

        window.addEventListener('offline', () => {
          this.isNetworkConnected = false;
        });

        // Initial check
        this.isNetworkConnected = navigator.onLine;
      }
    });
  }

  getNetworkStatus(): boolean {
    return this.isNetworkConnected;
  }

  sideMenu() {
    this.farm =
      [
        {
          title : 'Site',
          url   : '/tabs/entities/site',
          icon  : 'logo-buffer'
         },
        {
          title: 'Farm',
          url: '/tabs/entities/farm',
          icon: 'rose'
        },
        // {
        //   title : 'Farmer',
        //   url   : '/tabs/entities/farmer',
        //   icon  : 'person'
        //   },
        //   {
        //     title : 'Farmer Group',
        //      url   : '/tabs/entities/farmer-group',
        //      icon  : 'people-circle'
        //  },
          {
            title: 'Device',
            url: '/tabs/entities/device',
            icon: 'phone-portrait'
          },
              {
          title: 'Calendar',
          url: '/tabs/entities/calendar',
          icon: 'calendar-clear'
        },
          // {
          //   title: 'Season',
          //   url: '/tabs/entities/season',
          //   icon: 'rainy'
          // },
          // {
          //   title: 'Plant',
          //   url: '/tabs/entities/plant',
          //   icon: 'flower'
          // },
          {
            title: 'Crop',
            url: '/tabs/entities/crop',
            icon: 'rose'
          },
      
          
          // {
          //   title: 'task',
          //   url: '/tabs/entities/task',
          //   icon: 'flower'
          // },
          
        // {
        //   title: 'Plant Factory',
        //   url: '/tabs/entities/plant-factory',
        //   icon: 'leaf'
        // },
        // {
        //   title: 'Zone',
        //   url: '/tabs/entities/zone',
        //   icon: 'pin'
        // },
        // {
        //   title : 'Zone Device',
        //   url   : '/tabs/entities/zone-device',
        //   icon  : 'phone-portrait'
        // },
        // {
        //   title : 'Zone Telemetry',
        //   url   : '/tabs/entities/zone-telemetry',
        //   icon  : 'radio'
        // },
      //   {
      //     title: 'Address',
      //     url: '/tabs/entities/address',
      //     icon: 'location'
      //   },
      // {
      //   title : 'Map Tile',
      //   url   : '/tabs/entities/map-tile',
      //   icon  : 'globe'
      // },
      
        // {
        //   title: 'Device Model',
        //   url: '/tabs/entities/device-model',
        //   icon: 'play'
        // },
      ];
      this.admin=[
        {
          title : 'User Management',
          url   : '/user-management',
          icon  : 'people'
        },
        {
          title : 'Privilege Management',
          url   : '/role-permission-management',
          icon  : 'checkmark-done'
        },
        {
          title : 'Upgrade License',
          url   : '/license',
          icon  : 'caret-up'
        },
      ];
    // this.season =
      [
       

        // {
        //   title: 'Seed',
        //   url: '/tabs/entities/seed',
        //   icon: 'egg'
        // },
        // {
        //   title: 'Raw Material',
        //   url: '/tabs/entities/raw-material',
        //   icon: 'cube'
        // },
        // {
        //   title: 'Tool',
        //   url: '/tabs/entities/tool',
        //   icon: 'hammer'
        // },
        // {
        //   title: 'Nutrient',
        //   url: '/tabs/entities/nutrient',
        //   icon: 'beaker'
        // },
      ];
    // this.crop =
      [

        // {
        //   title: 'Product',
        //   url: '/tabs/entities/product',
        //   icon: 'square'
        // },
        // {
        //   title: 'Breeder',
        //   url: '/tabs/entities/breeder',
        //   icon: 'person'
        // },
        // {
        //   title: 'Recipe',
        //   url: '/tabs/entities/recipe',
        //   icon: 'information-circle'
        // },
        // {
        //   title: 'Plant Factory Controller',
        //   url: '/tabs/entities/plant-factory-controller',
        //   icon: 'cube'
        // },
        // {
        //   title: 'Climate Controller',
        //   url: '/tabs/entities/climate-controller',
        //   icon: 'cloudy'
        // },
        // {
        //   title: 'Dosing Controller',
        //   url: '/tabs/entities/dosing-controller',
        //   icon: 'color-fill'
        // },
        // {
        //   title: 'Irrigation Controller',
        //   url: '/tabs/entities/irrigation-controller',
        //   icon: 'water'
        // },
        // {
        //   title: 'Lighting Controller',
        //   url: '/tabs/entities/lighting-controller',
        //   icon: 'bulb'
        // },
        // {
        //   title: 'Lighting Spectrum Controller',
        //   url: '/tabs/entities/lighting-spectrum-controller',
        //   icon: 'stats-chart'
        // },
        // {
        //   title: 'Weather Sensor',
        //   url: '/tabs/entities/weather-sensor',
        //   icon: 'thunderstorm'
        // },
        // {
        //   title: 'Environment Sensor',
        //   url: '/tabs/entities/aqua-sensor',
        //   icon: 'thermometer'
        // },
        // {
        //   title: 'Aqua Sensor',
        //   url: '/tabs/entities/aqua-sensor',
        //   icon: 'water'
        // },

      //   {
      //     title: 'Inspection Batch',
      //     url: '/tabs/entities/inspection-batch',
      //     icon: 'book'
      //   },
      //         {
      //   title : 'Crop Activity',
      //   url   : '/tabs/entities/crop-activity',
      //   icon  : "bookmarks"
      // },
      // {
      //   title : 'Crop Zone',
      //   url   : '/tabs/entities/crop-zone',
      //   icon  : "file-tray-full"
      // },
      //     {
      //   title : 'Expense',
      //   url   : '/tabs/entities/expense',
      //   icon  : 'pricetags'
      // },
      // {
      //   title: 'Event',
      //   url: '/tabs/entities/event',
      //   icon: 'today'
      // },
       
      //   {
      //     title: 'Alert',
      //     url: '/tabs/entities/alert',
      //     icon: 'alert'
      //   },
      //   {
      //     title: 'Reminder',
      //     url: '/tabs/entities/reminder',
      //     icon: 'notifications'
      //   },
      ];
    // this.harvest =
      [
        // {
        //   title: 'Harvest',
        //   url: '/tabs/entities/harvest',
        //   icon: 'cut'
        // },
        // {
        //   title: 'Lot',
        //   url: '/tabs/entities/lot',
        //   icon: 'medical'
        // },
      ];
      // this.admin=[
      //   {
      //     title : 'User Management',
      //     url   : '/user-management',
      //     icon  : 'people'
      //   },
      //   {
      //     title : 'Privilege Management',
      //     url   : '/roles-permission-management',
      //     icon  : 'checkmark-done'
      //   },
      //   {
      //     title : 'Upgrade License',
      //     url   : '/license',
      //     icon  : 'caret-up'
      //   },
      // ]
  }

  changeTheme($event) {
    // document.body.classList.add('dark');
    // console.log(this.theme)
    if (this.theme) {

      document.body.classList.remove('dark');
      this.theme = false
    }
    else {

      document.body.classList.add('dark');
      this.theme = true
    }

  }


  openactivities(){
    this.navController.navigateForward('tabs/activities')
  }

  openanalytics(){
    this.navController.navigateForward('tabs/home')
  }

  openinsights(){
    this.navController.navigateForward('tabs/account')
  }

};
